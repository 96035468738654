import React from "react";

import { colors } from "../../../assets/colors";
import { FaCircle } from "react-icons/fa";
import LogoIcon from "../../../assets/svgs/logo-colorful.inline.svg";
import Notification from "../../../assets/svgs/notifications-purple.inline.svg";
import ChatPurpleIcon from "../../../assets/svgs/chatbox-purple.inline.svg";
import { Container } from "./styles";

function CardKanban({ process, logo }) {
    const { channel, code, transportModality, qttComment, qttRecentUpdates } = process;
    let color;

    switch (channel?.toLowerCase()) {
        case "verde":
            color = colors.canal_verde;
            break;
        case "amarelo":
            color = colors.canal_amarelo;
            break;
        case "vermelho":
            color = colors.canal_vermelho;
            break;
        case "cinza":
            color = colors.canal_cinza;
            break;
        default:
            color = colors.C1;
    }

    return (
        <Container>
            <LogoIcon id="logo" />
            <FaCircle id="channel" size={10} color={color} />
            <strong>{code}</strong>
            <span>{transportModality?.toLowerCase()}</span>
            <div>
                {qttRecentUpdates > 0 && <Notification />}
                {qttComment > 0 && <ChatPurpleIcon className="ml-2" />}
            </div>
        </Container>
    );
}

export default CardKanban;
