import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";

import requests from "../../services/requests";
import Button from "../../components/Button";
import Card from "../../components/Cards";
import CardKanban from "../../components/Kanban/CardKanban";
import Layout from "../../components/Layout";
import Kanban from "../../components/Kanban";
import Submenu from "../../components/Menus/Submenu";
import SEO from "../../components/SEO";

const KanbanPage = () => {
    const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);

    let [load, setLoad] = useState(false);
    const [processQty, setProcessQty] = useState({
        view: 0,
        total: 0,
        pages: 0,
        currentPage: 0,
    });

    let [list, setList] = useState([]);

    const listProcess = () => {
        setLoad(true);
        if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
            requests
                .listProcessKanban({
                    search: searchString,
                    filters: filterInformation,
                    period: searchPeriod,
                })
                .then(data => {
                    setList(data.data);

                    setProcessQty({
                        view: data.meta.item_count,
                        total: data.meta.total_count,
                        currentPage: data.meta.current_page,
                        pages: data.meta.page_count,
                    });

                    setLoad(false);
                });
        }
    };

    const nextPage = () => {
        setLoad(true);

        requests
            .listProcessKanban(
                {
                    search: searchString,
                    filters: filterInformation,
                    period: searchPeriod,
                },
                processQty.currentPage + 1,
                {}
            )
            .then(data => {
                let aux = list;
                for (let i in data.data) {
                    aux.push(data.data[i]);
                }
                setList(aux);

                setProcessQty({
                    view: list.length,
                    total: data.meta.total_count,
                    currentPage: data.meta.current_page,
                    pages: data.meta.page_count,
                });

                setLoad(false);
            });
    };

    const [searchString, setSearchString] = useState("");

    const onChangeSearchString = value => {
        let search = value.trim();
        if (search.length > 2 || searchString.length >= search.length) {
            setSearchString(search);
        }
    };
    useEffect(() => {
        listProcess();
    }, [filterInformation, searchString, searchPeriod]);

    return (
        <Layout>
            <SEO title="Kanban" />
            <Submenu
                processQty={processQty}
                onChangeSearchString={onChangeSearchString}
                nextPage={nextPage}
                type={{ filters: true, infos: true, search: true }}
            />
            <div className="p-4">
                <Card>
                    <div className="flex justify-between my-4">
                        <h5 className="uppercase mb-2">Kanban</h5>
                        <div>
                            {!load && processQty.pages > processQty.currentPage && (
                                <Button className="font-small mb-2" onClick={nextPage}>
                                    Carregar mais processos
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="border-t border-C2 border-solid pt-4 pb-5 overflow-auto ">
                        {load ? (
                            <div className="flex flex-col items-center">
                                <ClipLoader size={20} loading={load} />
                            </div>
                        ) : (
                            <div className="flex md-fit xs:flex-wrap lg:flex-no-wrap">
                                <Kanban
                                    title="Pré-embarque"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Pré embarque") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                                <Kanban
                                    title="Trânsito"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Trânsito") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                                <Kanban
                                    title="Desembaraço"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Desembaraço") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                                <Kanban
                                    title="Faturamento"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Faturamento") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                                <Kanban
                                    title="Transporte"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Transporte") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                                <Kanban
                                    title="Entrega"
                                    className=" md-vw60 xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                    {list &&
                                        list.map((process, key) => {
                                            if (process.currentStageModality !== "Entrega") {
                                                return null;
                                            }
                                            return (
                                                <Link
                                                    key={key}
                                                    to={`/processos/detalhes/${process.identifier}`}
                                                    state={{ prevPath: window.location.pathname }}
                                                >
                                                    <CardKanban code={process.code} process={process} logo></CardKanban>
                                                </Link>
                                            );
                                        })}
                                </Kanban>
                            </div>
                        )}
                    </div>
                    {!load && processQty.pages > processQty.currentPage && (
                        <div className="flex flex-col items-center">
                            <Button className="mr-4 font-medium h-10" onClick={nextPage}>
                                Carregar mais processos
                            </Button>
                        </div>
                    )}
                </Card>
            </div>
        </Layout>
    );
};
export default KanbanPage;
