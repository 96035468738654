import styled from "styled-components";

import { colors } from "../../../assets/colors";

export const Container = styled.li`
    display: grid;
    grid-template-columns: 22px 1fr 36px 16px;

    grid-template-rows: 19px 23px;
    grid-template-areas:
        "img title title ."
        "svg f icons .";

    background: ${colors.C1};
    border-radius: 5px;
    max-width: 100%;
    padding: 4px 0px 4px 6px;
    margin-top: 7px;

    #logo {
        grid-area: img;
        align-self: flex-end;
        justify-self: center;
    }
    #channel {
        grid-area: svg;
        align-self: center;
        justify-self: center;
    }

    strong {
        display: table-cell;
        grid-area: title;
        color: ${colors.C6};
        font-size: 16px;
    }

    span {
        grid-area: f;
        font-size: 13px;
        color: ${colors.C6};
        align-self: center;
    }

    div {
        grid-area: icons;
        display: flex;
        justify-content: right;
        align-self: center;
        float: right;
    }
`;
